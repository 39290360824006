import { Builder } from '@builder.io/react'
import dynamic from 'next/dynamic'

Builder.registerComponent(
    dynamic(() =>
        import('./HomepageHeroWhiteLabel').then(
            (res) => res.HomepageHeroWhiteLabel as any
        )
    ),
    {
        name: 'Homepage Hero WhiteLabel',
        inputs: [
            {
                name: 'titleBeforeGradient',
                type: 'string',
                defaultValue: 'Buy',
            },
            {
                name: 'gradiantText',
                type: 'string',
                defaultValue: 'personalized',
            },
            {
                name: 'titleAfterGradient',
                type: 'string',
                defaultValue: 'gift cards & eGift cards!',
            },
            {
                name: 'subText',
                type: 'string',
                defaultValue:
                    'Give premium gift cards as unique as they are. Customizable and flexible for any occasion and milestone. Fast shipping.',
            },
            {
                name: 'gradientColor1',
                type: 'color',
                defaultValue: '#6959f6',
            },
            {
                name: 'gradientColor2',
                type: 'color',
                defaultValue: '#4874f6',
            },
            {
                name: 'buttonText',
                type: 'string',
                defaultValue: 'Buy Now',
            },
            {
                name: 'buttonUrl',
                type: 'string',
                defaultValue: '/visa-gift-cards',
            },
            {
                name: 'button2Text',
                type: 'string',
                defaultValue: 'Buy in bulk',
            },
            {
                name: 'button2Url',
                type: 'string',
                defaultValue: '/corporate/bulk',
            },
            {
                name: 'nationalBrandsValue',
                type: 'string',
                defaultValue: '10K+',
            },
            {
                name: 'localBrandsValue',
                type: 'string',
                defaultValue: '20K+',
            },
            {
                name: 'accessoryText',
                type: 'string',
                defaultValue: 'Deliver by text or email!',
            },
            {
                name: 'image',
                type: 'file',
                allowedFileTypes: ['jpeg', 'png'],
                defaultValue: 'https://place-hold.it/674x613',
            },
        ],
    }
)
