import { Builder } from '@builder.io/react'
import dynamic from 'next/dynamic'

export const LogoSliderInputs = [
    {
        name: 'rows',
        type: 'number',
        defaultValue: 2,
        helperText: 'The amount of rows in the slider'
    },
    {
        name: 'speed',
        type: 'number',
        defaultValue: 8,
        helperText: 'The speed of the slider transition. Lower number is faster'
    },
    {
        name: 'logoWidth',
        type: 'number',
        defaultValue: 200,
        helperText: 'The width of the logo. Useful if there are not many logos to use.'
    },
    {
        name: 'logos',
        type: 'list',
        defaultValue: [...Array(30).keys()].map(key => ({
            url: 'https://perfectgift-consumer-git-pgc-10547-testimo-8208d4-telos-gifting.vercel.app/logo-horizontal-corporate.svg',
            width: 200,
            height: 63.33
        })),
        subFields: [
            {
                name: 'url',
                type: 'file',
                allowedFileTypes: ['jpeg', 'png'],
                required: true,
                friendlyName: 'Logo Image',
            },
            {
                name: 'width',
                type: 'number',
                defaultValue: 200,
            },
            {
                name: 'height',
                type: 'number',
                defaultValue: 63.33
            },
        ],
        helperText: 'array objects',
        friendlyName: 'Slides array. Make sure the number of slides is evenly divisible by the number of rows. The remainders will be excluded from slide',
    },
]

Builder.registerComponent(
    dynamic(() => import('./LogoSlider').then((res) => res as any)),
    {
        name: 'Logo Slider',
        inputs: LogoSliderInputs,
    }
)
