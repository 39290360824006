import { Builder } from '@builder.io/react'
import dynamic from 'next/dynamic'

export const TestimonialSliderInputs = [
    {
        name: 'timeout',
        type: 'number',
        defaultValue: 4000,
        helperText: 'The length of time in ms that the slide stays'
    },
    {
        name: 'slides',
        type: 'list',
        defaultValue: [{
            author: 'Sample Name',
            headline: 'Sample headline',
            text: 'Sample full text',
            logoUrl: 'https://www.perfectgift.com/logo-horizontal-corporate.svg',
            featuredImageUrl: 'https://www.perfectgift.com/logo-horizontal-corporate.svg',
            starRating: 4
        }],
        subFields: [
            {
                name: 'author',
                type: 'string',
                defaultValue: 'Sample Name',
            },
            {
                name: 'headline',
                type: 'string',
                defaultValue: 'Sample headline',
            },
            {
                name: 'text',
                type: 'richText',
                defaultValue: 'Sample full quote',
            },
            {
                name: 'logoUrl',
                defaultValue: 'https://www.perfectgift.com/logo-horizontal-corporate.svg',
                type: 'file',
                allowedFileTypes: ['jpeg', 'png'],
                required: true,
                friendlyName: 'Slide Logo Image',
            },
            {
                name: 'featuredImageUrl',
                defaultValue: 'https://www.perfectgift.com/logo-horizontal-corporate.svg',
                type: 'file',
                allowedFileTypes: ['jpeg', 'png'],
                required: true,
                friendlyName: 'Slide Featured Image',
            },
            {
                name: 'starRating',
                type: 'number',
                defaultValue: 4
            },
        ],
        helperText: 'array objects',
        friendlyName: 'Slides array',
    },
]

Builder.registerComponent(
    dynamic(() => import('./TestimonialSlider').then((res) => res as any)),
    {
        name: 'Testimonial Slider',
        inputs: TestimonialSliderInputs,
    }
)
